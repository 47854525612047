.settingsLayout {
  min-height: calc(100vh - 64px);
  background: #fff;
}

.sider {
  background: #fff;
  border-right: 1px solid #f0f0f0;
}

.content {
  padding: 24px 40px;
  max-width: 800px;
}

.section {
  max-width: 600px;
}

.section h2 {
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 500;
}

.section h3 {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
}

.consentItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
}

.consentItem p {
  color: #666;
  margin: 0;
}

.deleteAccount {
  background: #fff1f0;
  border: 1px solid #ffa39e;
  border-radius: 8px;
  padding: 24px;
  margin-top: 24px;
}

.deleteAccount ul {
  margin: 16px 0 24px;
  padding-left: 20px;
}

.deleteAccount li {
  color: #666;
  margin-bottom: 8px;
}