.category-menu-container {
  position: relative;
}

.ant-menu-submenu-popup {
  position: absolute !important;
}

.ant-menu-horizontal {
  line-height: 46px;
  overflow: visible !important;
}

.category-icon-container {
  position: relative;
  display: inline-block;
}

.emoji-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  margin-top: 4px;
}

.emoji-dropdown-content {
  background: white;
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  white-space: nowrap;
  width: 320px;
}

/* 탭 스타일링 */
.emoji-tab-label {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
}

.emoji-tab-text {
  display: none;
}

.emoji-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 4px;
  max-height: 200px;
  overflow-y: auto;
  padding: 8px 0;
}

.emoji-button {
  padding: 4px !important;
  min-width: 32px !important;
  height: 32px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 16px !important;
}

.emoji-button:hover {
  background-color: #f5f5f5;
}

.ant-menu-horizontal > .ant-menu-submenu > .ant-menu {
  top: 100%;
  position: relative;
  margin-top: 0;
}

/* 탭 스타일 커스터마이징 */
.ant-tabs-nav {
  margin-bottom: 8px !important;
}

.ant-tabs-tab {
  padding: 8px !important;
}

.ant-tabs-content {
  height: auto !important;
}

/* 스크롤바 스타일링 */
.emoji-grid::-webkit-scrollbar {
  width: 6px;
}

.emoji-grid::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.emoji-grid::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.emoji-grid::-webkit-scrollbar-thumb:hover {
  background: #555;
}