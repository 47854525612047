.timelineWrapper {
    position: fixed;
    right: 24px;
    bottom: 24px;
    z-index: 1000;
  }
  
  .floatingButton {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid #e8e8e8;
  }
  
  .floatingButton:hover {
    transform: scale(1.1);
    transition: transform 0.2s;
    background-color: #f5f5f5;
  }
  
  .dropdownContent {
    position: absolute;
    bottom: 52px;  /* floatingButton 위로 올라오도록 */
    right: 0;
    width: 400px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    animation: slideUp 0.2s ease-out;
  }
  
  @keyframes slideUp {
    from {
      transform: translateY(10px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .dropdownHeader {
    padding: 16px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;  /* 상단 정렬로 변경 */
  }
  
  .dropdownHeader h3 {
    margin: 0;
    margin-bottom: 4px;  /* 서브텍스트와의 간격 */
    font-size: 16px;
    font-weight: 500;
  }
  
  .headerSubtext {
    font-size: 12px;
    color: #8c8c8c;
    font-weight: normal;
  }
  
  .headerSubtext span {
    position: relative;
    display: inline-block;
    padding-left: 8px;
  }
  
  /* .headerSubtext span::before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #8c8c8c;
  } */
  
  .dropdownBody {
    max-height: 400px;
    overflow-y: auto;
    padding: 16px;
  }
  
  /* 기존 스타일 유지 */
  .timelineContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .timelineItem {
    display: flex;
    gap: 16px;
    cursor: pointer;
    padding: 4px;
    border-radius: 6px;
    transition: background-color 0.2s;
    width: 100%;
  }
  
  .timelineItem:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
  
  .timeStamp {
    min-width: 50px;
    color: #8c8c8c;
    font-size: 14px;
    padding-top: 2px;
    flex-shrink: 0;
  }
  
  .content {
    flex: 1;
    padding: 0 0 0 12px;
    min-height: 50px;
    overflow: hidden;
  }
  
  .title {
    font-weight: 500;
    margin-bottom: 4px;
    color: #262626;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  
  .category {
    font-size: 12px;
    color: #8c8c8c;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .centerContent {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }