.customScrollbar::-webkit-scrollbar {
  width: 8px;
}

.customScrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.customScrollbar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);  /* 0.2에서 0.1로 변경하여 더 밝게 */
  border-radius: 4px;
}

.customScrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);  /* 0.3에서 0.2로 변경하여 hover 시에도 더 밝게 */
}