.card {
  position: relative;
  overflow: hidden;
}

.imageContainer {
  position: relative;
  height: 200px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.overlay.hovered {
  opacity: 1;
}

.keywords {
  color: white;
  text-align: center;
  padding: 10px;
}

.keyword {
  display: inline-block;
  margin: 0 5px 5px 0;
  padding: 5px 12px;  /* 패딩을 약간 증가 */
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 20px;  /* 더 동그란 모서리를 위해 값을 증가 */
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;  /* 부드러운 호버 효과를 위한 전환 추가 */
}

.actions {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
  display: flex;
  gap: 15px;
  transition: color 0.3s ease;
  color: rgba(0, 0, 0, 0.65);
}

.actions.hovered {
  color: white;
}

.icon {
  font-size: 20px;
  cursor: pointer;
}

.iconContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
  margin-bottom: 10px;
  gap: 15px;
}

.summary {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.fullSummary {
  max-width: 300px;
  max-height: 200px;
  overflow: auto;
  padding: 10px;
}

